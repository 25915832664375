const Button = ({
    text = "",
    fillColor = null,
    borderColor = null,
    onClick = () => {},
    addClasses = "",
    img = null
}) => {
    return(
        <button 
            className={"min-h-[37px] flex items-center gap-2 georama " + (addClasses + " ") + (fillColor ? "bg-" + fillColor + " " : " ") + (borderColor ? "border-2 border-" + borderColor : "")}
            onClick={onClick}
        >
            {
                img &&
                <img
                    src={img}
                    className="h-[20px]"
                />
            }

            <p className="text-white text-[20px]">
                {text}
            </p>
        </button>
    )
}

export default Button