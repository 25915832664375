import logo from '../assets/logo.png';
import logo_w from '../assets/logo_white.png';
import arb from "../assets/arb.png"
import Button from './Button';
import { GiHamburgerMenu } from "react-icons/gi";
import { useMediaQuery } from 'react-responsive'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useEffect } from 'react';

const Header = () => {
    const desktopMenu = useMediaQuery({ query: '(min-width: 502px)' })
    const modal = useWeb3Modal()
    const { address, chainId, isConnected } = useWeb3ModalAccount()

    return (
        <div className='w-full h-[74px] flex items-center justify-between px-5 sp2:px-16 bg-black fixed top-0 left-0 z-50'>
            <div className='flex items-center gap-2'>
                <img
                    src={logo_w}
                    className='h-[74px]'
                />

                <h1 className='text-[29px] text-white'>
                    CUBHUB
                </h1>
            </div>
            <div className='flex items-center gap-5'>
                <img src={arb} className='h-[30px]' />

                <Button
                    text={address ? address.trim().substring(0,7) + ".." : 'CONNECT'}
                    borderColor={"lightBlue"}
                    addClasses='font-semibold px-5 rounded-xl'
                    onClick={() => {
                        try{
                            console.log("HEYYY")
                            if(!address){
                                modal.open()
                            }
                            else console.log("EHNNO")
                        } catch(e){
                            console.log(e)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default Header