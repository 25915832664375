import Header from './component/Header';
import bear from "./assets/bear.jpeg"
import grid1 from "./assets/grid1.png"
import grid2 from "./assets/grid2.jpeg"
import grid3 from "./assets/grid3.jpeg"
import paws from "./assets/paws.png"
import dripbear from "./assets/dripbear.png"
import bears from "./assets/bears.png"
import Separator from './component/Separator';
import Filter from './component/Filter';
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider
} from '@web3modal/ethers/react'
import { ethersConfig, chains, alchemyConfig, blockchain, serviceUrl, whitelistedCollections, faqList, gatingList } from './utils/constants';
import { useEffect, useRef, useState } from 'react';
import { Alchemy, Network } from "alchemy-sdk";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "react-icons/md";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import LoadNft from './component/LoadNft';
import { AlchemyProvider, BrowserProvider, Contract, InfuraProvider, JsonRpcProvider, ethers, formatUnits, parseUnits } from 'ethers';
import { useSendTransaction } from 'wagmi';
import { v4 } from 'uuid';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Button from './component/Button';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import abi from "./assets/abi.json"
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

createWeb3Modal({
  ethersConfig,
  chains,
  //projectId: "ebe61b111b1a4af8b0b8035093a43447",
  projectId: process.env.REACT_APP_WALLET_PROJECT_ID,
  enableAnalytics: true,
  themeMode: 'dark'
})

gsap.registerPlugin(ScrollTrigger);

function App() {
  const alchemy = new Alchemy(alchemyConfig);
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [nftList, setList] = useState([])
  const [selectedNfts, setSelected] = useState([])
  const [currentSlide, setSlide] = useState(1)
  const [openSendMail, setOpenSendMail] = useState(false);
  const [openCheckOtp, setOpenCheckOtp] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [otpSent, setOtpSent] = useState(false)
  const [ds, setDs] = useState("")
  const [platform, setPlatform] = useState("instagram")
  const [eligibleFreeOrders, setFreeOrders] = useState([false, 0])

  const [swiperRef, setSwiperRef] = useState(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const emailRef = useRef(null)
  const dsRef = useRef(null)
  const otpRef = useRef(null)

  const imgDivRef = useRef(null)
  const heroRef = useRef()
  const hereRef = useRef()

  const loadUserNFTs = async () => {
    const req = await fetch(`${serviceUrl}/userOrders/${address}`)
    const res = await req.json()

    if (res.result != "OK") {
      toast("Could not load use NFTs", {
        type: "error",
        autoClose: 2000
      })
      return
    }

    const nfts = await alchemy.nft.getNftsForOwner(address);
    let finalNftList = []
    console.log("MIO OUTCOME =>", res.outcome)
    const toFilter = res.outcome ? res.outcome : []
    console.log("MIO TO FILTER =>", toFilter)

    const freeOrdersUsed = toFilter.filter((order) => order.free_order)
    const provider = new BrowserProvider(walletProvider, "arbitrum")
    let totalFreeOrders = 0

    for (let i = 0; i < gatingList.length; i++) {
      const contract = new Contract(gatingList[i], abi, provider)

      const balance = await contract.balanceOf(address)
      const num = Number(balance.toString())

      if (!isNaN(num)) totalFreeOrders += num
    }

    const toalAvailable = totalFreeOrders - freeOrdersUsed
    setFreeOrders([toalAvailable > 0, toalAvailable])

    for (let i = 0; i < nfts.ownedNfts.length; i++) {
      const nft = nfts.ownedNfts[i]
      const filtered = toFilter.filter((item) => {
        const items = item.items
        let filterItems = []

        if (items) filterItems = items.filter((it) => it.contract == nft.contract.address && it.tokenId == nft.tokenId)

        return filterItems.length > 0
      })

      if (filtered.length == 0) finalNftList.push(nft)
    }

    const size = 6;
    let splittedArray = [];
    for (let i = 0; i < finalNftList.length; i += size) {
      splittedArray.push(finalNftList.slice(i, i + size));
    }

    console.log("ARRAY OF ARRAYS =>", splittedArray)

    setList(splittedArray)
  }

  const sendTxnCubhub = async () => {
    const toastId = toast.loading("Processing your request", {
      autoClose: 5
    })

    try {
      const req1 = await fetch(`${serviceUrl}/checkOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          otp: otpRef.current.value
        }),
      })

      const res1 = await req1.json()

      if (res1.result && res1.result == "KO_INVALID") {
        console.log("INVALID =>", res1)
        toast.update(toastId, {
          render: "Invalid OTP Code",
          type: "warning",
          isLoading: false,
          autoClose: 2000
        })

        setOpenCheckOtp(false)

        return
      }
      else if (res1.result == "KO") {
        toast.update(toastId, {
          render: res1.reason,
          type: "error",
          isLoading: false,
          autoClose: 2000
        })

        setOtpSent(false)
        setDs("")
        setOpenCheckOtp(false)

        return
      }
      else toast.update(toastId, {
        render: "Processing transaction",
        autoClose: 5
      })

      const provider = new BrowserProvider(walletProvider, "arbitrum")

      const signer = await provider.getSigner()

      const txn = await signer.sendTransaction({
        to: blockchain.receiverAddress,
        value: eligibleFreeOrders[1] > 0 ? "0" : String(Number(blockchain.price) * selectedNfts.length)
      })

      const receipt = await txn.wait()
      const hash = receipt.hash
      //const hash = "test hash"

      toast.update(toastId, {
        render: "Submitting the order"
      })

      const req = await fetch(`${serviceUrl}/placeOrder/${hash}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          email: otpSent,
          discord: ds,
          address: address,
          platform: platform,
          items: selectedNfts.map((selected) => {
            return {
              _id: v4(),
              _key: v4(),
              _type: "item",
              contract: selected.contract.address,
              tokenId: selected.tokenId,
              tokenUri: selected.tokenUri
            }
          }),
          free_order: eligibleFreeOrders[1] > 0
        }),
      })

      const res = await req.json()

      if (res.result && res.result == "OK") {
        toast.update(toastId, {
          render: "Order was submitted!",
          type: "success",
          isLoading: false,
          autoClose: 2000
        })
      }
      else {
        toast.update(toastId, {
          render: res.reason,
          type: "error",
          isLoading: false,
          autoClose: 2000
        })
      }

      setOtpSent(false)
      setDs("")
      setOpenCheckOtp(false)
      loadUserNFTs()
      setSelected([])
    } catch (e) {
      console.log("ERROR =>", e)
      toast.update(toastId, {
        render: "Something went wrong! (Could be your ARB balance)",
        type: "error",
        isLoading: false,
        autoClose: 2000
      })

      setOtpSent(false)
      setDs("")
      setOpenCheckOtp(false)
    }
  }

  const sendOtpEmail = async () => {
    try {
      if (emailRef.current.value && emailRef.current.value.trim() != "") {
        const req = await fetch(`${serviceUrl}/otpEmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({
            email: emailRef.current.value
          })
        })

        const res = await req.json()

        if (res.result && res.result == "OK") {
          setOtpSent(emailRef.current.value)
          setDs(dsRef.current.value)
          setOpenCheckOtp(true)
          setOpenSendMail(false)
        } else {
          console.log("ERROR API =>", res)
        }
      } else {
        toast("You have to input an email", {
          type: "error",
          autoClose: 2000
        })
      }
    } catch (e) {
      console.log("ERROR =>", e)
      setOpenSendMail(false)
    }
  }

  const checkWhitelisted = (address) => {
    return whitelistedCollections.includes(address.toLowerCase())
  }

  useEffect(() => {
    if (address) loadUserNFTs()
  }, [address])

  useGSAP(() => {
    gsap.fromTo("#white-line", {
      width: 0
    }, {
      width: "100%",
      duration: 1.5,
      delay: 0.6
    })
  }, { scope: heroRef.current })

  useGSAP(() => {
    gsap.fromTo(".filterBtn", {
      translateX: window.innerWidth,
    }, {
      translateX: 0,
      duration: 1.7,
      ease: "bounce.inOut",
      stagger: {
        amount: 0.4
      },
      scrollTrigger: {
        trigger: hereRef.current,
        start: 'top bottom',
        end: 'bottom top',
        toggleClass: "_active",
        toggleActions: 'play none play none',
        once: true,
      },
    })
  }, { scope: hereRef.current })

  useGSAP(() => {
    gsap.fromTo(".fadeLetter", {
      marginBottom: 10,
      opacity: 0
    }, {
      marginBottom: 0,
      opacity: 1,
      duration: 1,
      stagger: {
        amount: 1
      },
      scrollTrigger: {
        trigger: hereRef.current,
        start: 'top bottom',
        end: 'bottom top',
        toggleClass: "_active",
        toggleActions: 'play none play none',
        once: true,
      },
    })
  }, { scope: hereRef.current })

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={true}
        pauseOnHover
        limit={3}
        theme="dark"
      />
      <div id='gradient' className='w-full min-h-screen relative pt-[200px] pb-[100px] flex flex-col items-center px-10 xs:px-16 overflow-hidden'>
        <Header />

        <div ref={heroRef} className='w-full max-w-[700px] lg:max-w-[1240px] h-fit grid grid-cols-1 lg:grid-cols-2 gap-16'>
          <p className='text-[50px] xxs:text-[68px] font-bold leading-[50px] xxs:leading-[70px] col-span-1 mt-5 text-center lg:text-left'>
            <span className='text-[80px] xxs:text-[122px]'>FILTER</span>
            <br />
            GENERATOR
          </p>

          <div className='col-span-1 flex flex-col justify-between h-full'>
            <p className='text-white georama text-[32px] m-0 text-center lg:text-left'>
              Create your own filter and never have to worry about doxing in videos again.
            </p>

            <div className='flex items-center justify-between mt-10 gap-5'>
              <div id='white-line' className='w-full h-[1px] bg-white' />

              <div className='bg-yellowRange rounded-full px-2 py-1 text-center border border-white'>
                <p className='georama text-white text-[20px] min-w-[96px]'>$55 USD</p>
              </div>
            </div>
          </div>
        </div>

        <div ref={imgDivRef} className='w-full max-w-[700px] lg:max-w-[1240px] h-fit mt-20'>
          <div className='w-full grid grid-cols-3 gap-10 sp:gap-20 mt-5'>
            {
              [grid2, grid3, grid1].map((item, i) => (
                <img src={item} key={i} className='w-full gridimg rounded-lg col-span-1 border-2 border-white' />
              ))
            }
          </div>
        </div>

        <div ref={hereRef} className='w-full max-w-[700px] lg:max-w-[1240px] h-fit mt-36 flex items-end justify-center sp:justify-between'>
          <img src={paws} className='max-w-[150px] lg:max-w-[250px] reflected mb-10 hidden sp:block' />

          <div className='flex flex-col items-center h-full'>
            <p className='georama font-bold text-black text-[45px] text-center'>
              {"START HERE".split('').map((lt) => (
                <span className='fadeLetter'>{lt}</span>
              ))}
            </p>
            <img src={bears} className='max-w-[250px] lg:max-w-[350px]' />
          </div>

          <img src={paws} className='max-w-[150px] lg:max-w-[250px] mb-10 hidden sp:block' />
        </div>

        <div className='w-full max-w-[700px] lg:max-w-[1240px] h-fit flex flex-col sp:flex-row items-center justify-between border-b border-t border-white py-10 gap-5'>
          <p className='text-[30px] text-white georama text-center xxs:text-left'>
            Simply select your platform
          </p>

          <div className='w-fit h-fit flex flex-col xxs:flex-row gap-10'>
            <Button
              text='SNAPCHAT'
              borderColor={"white"}
              fillColor={platform == "snapchat" ? "yellowRange" : null}
              addClasses='filterBtn py-2 px-10 rounded-2xl'
              onClick={() => {
                setPlatform("snapchat")
              }}
            />

            <Button
              text='INSTAGRAM'
              borderColor={"white"}
              fillColor={platform == "instagram" ? "yellowRange" : null}
              addClasses='filterBtn py-2 px-10 rounded-2xl'
              onClick={() => {
                setPlatform("instagram")
              }}
            />
          </div>
        </div>

        <div className='w-full max-w-[700px] lg:max-w-[1240px] h-fit mt-44 flex items-center gap-5'>
          <div className='w-full h-[1px] bg-white' />

          <p className='georama font-bold text-white text-[45px] min-w-[260px] text-center'>SELECT NFT</p>

          <div className='w-full h-[1px] bg-white' />
        </div>

        <div className='w-full max-w-[700px] lg:max-w-[1240px] h-fit mt-20'>
          {
            address ?
              <>
                {
                  nftList.length > 1 &&
                  <div className='w-full mt-5 flex justify-center'>
                    <div className='w-full px-10 flex items-center justify-between'>
                      <div
                        className="prev-arrow cursor-pointer"
                        //ref={navigationPrevRef}
                        onClick={() => {
                          if (swiperRef) swiperRef.slidePrev()
                        }}
                      >
                        <MdOutlineKeyboardArrowLeft size={40} color='#FFF' />
                      </div>

                      <p className='text-[25px] text-white'>{currentSlide + " / " + nftList.length}</p>

                      <div
                        className="next-arrow cursor-pointer"
                        onClick={() => {
                          if (swiperRef) swiperRef.slideNext()
                        }}
                      >
                        <MdOutlineKeyboardArrowRight size={40} color='#FFF' />
                      </div>
                    </div>
                  </div>
                }

                {
                  nftList.length > 1 ?
                    <Swiper
                      // install Swiper modules
                      modules={[Navigation, Pagination]}
                      spaceBetween={10}
                      slidesPerView={1}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      onInit={(swiper) => {
                        setSlide(swiper.realIndex + 1)
                        setSwiperRef(swiper)
                        /*swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;*/
                      }}
                      pagination={{ clickable: true }}
                      onSwiper={(swiper) => {
                        console.log(swiper)
                      }}
                      allowTouchMove={false}
                      onSlideChange={(swiper) => {
                        setSlide(swiper.realIndex + 1)
                        console.log('slide change')
                      }}
                    >
                      {
                        nftList.map((list, index) => (
                          <SwiperSlide key={"list_" + index}>
                            <div className='w-full grid grid-cols-3 gap-5 mt-7'>
                              {
                                list.map((nft, i) => {

                                  const filtered = selectedNfts.filter((it) => it.tokenId == nft.tokenId && it.contract.address == nft.contract.address)

                                  return (
                                    <LoadNft
                                      nftUri={nft.tokenUri}
                                      selected={filtered.length > 0}
                                      whitelisted={checkWhitelisted(nft.contract.address)}
                                      key={i}
                                      onClick={() => {
                                        if (filtered.length > 0) {
                                          const filteredWithout = selectedNfts.filter((it) => it.tokenId != nft.tokenId || it.contract.address != nft.contract.address)

                                          setSelected(filteredWithout)
                                        }
                                        else {
                                          setSelected([
                                            ...selectedNfts,
                                            nft
                                          ])
                                        }
                                      }}
                                    />
                                  )
                                })
                              }
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                    :
                    <p className='w-full text-white text-center text-[30px] mt-10 georama font-semibold min-h-[400px] flex items-center justify-center'>
                      You don't have any NFT
                    </p>
                }

                {
                  nftList.length > 1 &&
                  <div className='w-full flex items-center h-fit gap-10 mt-5 px-0 sp:px-5'>
                    <div className='text-[25px] text-white border-2 border-white px-6 py-3 rounded-2xl bg-yellowRange georama font-bold'>
                      {selectedNfts.length}
                    </div>

                    <button
                      className='text-[25px] text-white border-2 border-white px-5 py-3 text-center w-full rounded-2xl bg-yellowRange georama font-bold'
                      onClick={async () => {
                        if (selectedNfts.length > 0) {
                          for (let i = 0; i < selectedNfts.length; i++) {
                            const nft = selectedNfts[i]

                            if (!checkWhitelisted(nft.contract.address)) {
                              setOpenError(true)
                              return
                            }
                          }

                          if (otpSent) setOpenCheckOtp(true)
                          else setOpenSendMail(true)
                        }
                      }}
                    >
                      BUY NOW
                    </button>
                  </div>
                }
              </>
              :
              <p className='text-[35px] text-white text-center w-full mt-20 rounded-2xl border-2 border-white georama font-semibold min-h-[400px] flex items-center justify-center'>
                Please connect your wallet
              </p>
          }
        </div>

        <div className='w-full max-w-[700px] lg:max-w-[1240px] h-fit mt-44 px-10'>
          <div className='w-full flex flex-col lg:flex-row gap-16 items-center lg:items-start'>
            <div className='w-full flex flex-col items-center h-full justify-start text-white text-[30px] georama mt-10 gap-10 font-semibold text-center lg:text-left'>
              <p>As long as you maintain ownership of your NFT, the filter never expires! </p>
              <div className='w-[70%] h-[2px] bg-white' />
              <p>Please allow for 3 business days for your filter will be emailed to you.</p>
            </div>

            <img src={dripbear} className='w-[360px]' />
          </div>
        </div>

        <div className='w-full max-w-[700px] lg:max-w-[1240px] h-fit border-2 border-white rounded-2xl px-20 py-10 bg-yellowRange'>
          <p className='georama text-white text-center font-bold text-[35px]'>
            We are working with new projects to allow them to incorporate their NFTs into our generator!
          </p>
        </div>

        {
          false &&
          <div className='w-full max-w-[700px] xl:max-w-[1240px] mt-20'>
            <p className='text-[35px] font-bold'>
              FAQ
            </p>

            {
              faqList.map((faq, index) => (
                <div className={'w-full ' + (index > 0 ? "mt-10" : "mt-5")} key={index}>
                  <div className={'w-full border-2 border-black rounded-lg py-2 px-5 text-[30px]'}>
                    {faq.question}
                  </div>

                  <p className='w-full px-5 mt-3 text-[20px]'>
                    {faq.answer}
                  </p>
                </div>
              ))
            }
          </div>
        }

        <Modal
          open={openSendMail}
          onClose={() => {
            setOpenSendMail(false)
          }}
          center
        >
          <h2 className='text-[35px]'>Please verify your email</h2>

          <p className='text-[20px] mt-5'>Email*</p>

          <input
            type='email'
            placeholder='Insert your email'
            className='w-full pl-5 text-[23px] outline-none border border-black rounded-lg mt-2 py-1'
            ref={emailRef}
          />

          <p className='text-[20px] mt-5'>Discord</p>

          <input
            type='text'
            placeholder='Insert your discord @'
            className='w-full pl-5 text-[23px] outline-none border border-black rounded-lg mt-2 py-1'
            ref={dsRef}
          />

          <div className='w-full flex justify-center pt-10'>
            <Button
              text='SEND MAIL'
              onClick={sendOtpEmail}
            />
          </div>
        </Modal>

        <Modal
          open={openCheckOtp}
          onClose={() => {
            setOpenCheckOtp(false)
          }}
          center
        >
          <h2 className='text-[35px]'>Confirm your email and place the order</h2>

          <p className='text-[20px] mt-5'>OTP Code</p>
          <input
            type='text'
            placeholder='Insert code'
            className='w-full pl-5 text-[23px] outline-none border border-black rounded-lg mt-2 py-1'
            ref={otpRef}
          />

          <div className='w-full flex justify-center pt-10'>
            <Button
              text='PLACE ORDER'
              onClick={sendTxnCubhub}
            />
          </div>
        </Modal>

        <Modal
          open={openError}
          onClose={() => {
            setOpenError(false)
          }}
          center
        >
          <h2 className='text-[35px]'>Order cannot be processed</h2>

          <p className='text-[20px] mt-5'>Some of the selected NFTs are not whitelisted</p>
        </Modal>

        <Modal
          open={eligibleFreeOrders[0]}
          onClose={() => {
            setFreeOrders([false, eligibleFreeOrders[1]])
          }}
          center
        >
          <h2 className='text-[35px]'>You are eligible to free orders</h2>

          <p className='text-[20px] mt-5'>Congratulations! You own a total of {eligibleFreeOrders[1]} NFT(s) from eligible contracts!</p>
        </Modal>
      </div>
    </>
  );
}

export default App;
