import { useEffect, useState } from "react"
import grid1 from "../assets/grid1.png"
import FadeLoader from "react-spinners/FadeLoader";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";

const LoadNft = ({
    nftUri = "",
    selected = false,
    whitelisted = true,
    onClick = () => {}
}) => {
    const [loading, isLoading] = useState(true)
    const [src, setSrc] = useState(grid1)

    const loadSrcLink = async () => {
        try {
            const req = await fetch(nftUri)
            const res = await req.json()
            setSrc(res.image.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/"))
            isLoading(false)
        } catch (e) {
            console.log("Could not load image")
            isLoading(false)
        }
    }

    useEffect(() => {
        loadSrcLink()
    }, [nftUri])

    return (
        !loading ?
            <div className="col-span-1 relative rounded-lg overflow-hidden cursor-pointer transition-all hover:scale-110" onClick={onClick}>
                <img src={src} className='w-full' />
                {
                    (src == grid1 || selected) &&
                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 flex items-center justify-center text-center text-4xl text-white">
                        {
                            selected ? 
                                (
                                    whitelisted ?
                                        <FaRegCheckCircle
                                            size={50}
                                            fill="#3de369"
                                        />
                                        :
                                        <FaRegCircleXmark
                                            size={50}
                                            fill="#ed544a"
                                        />
                                )
                                :
                                "Could not load nft"
                        }
                    </div>
                }
            </div>
            :
            <div className="w-full max-w-[182px] h-full max-h-[182px] col-span-1 flex items-center justify-center">
                <FadeLoader
                    color={"#000"}
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
    )
}

export default LoadNft