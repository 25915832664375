import { defaultConfig } from '@web3modal/ethers/react'
import { Network } from "alchemy-sdk";
import abi from "../assets/abi.json"

export const serviceUrl = "https://filterbackend.com"

export const chains = [
    {
        chainId: 42161,
        name: 'Arbitrum One',
        currency: 'ARB ETH',
        explorerUrl: 'https://arbiscan.io/',
        rpcUrl: 'https://arb1.arbitrum.io/rpc'
    },
    /*{
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    },*/
    /*{
        chainId: 5,
        name: 'Goerli Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://goerli.etherscan.io/',
        rpcUrl: 'https://ethereum-goerli.publicnode.com'
    }*/
]

export const ethersConfig = defaultConfig({
    metadata: {
        name: 'Cubhub',
        description: 'Lazydevs Dapp',
        url: 'https://web3modal.com',
        icons: ['']
    },
    defaultChainId: 42161,
    //defaultChainId: 1,
    rpcUrl: 'https://cloudflare-eth.com'
})

export const alchemyConfig = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    //apiKey: "zqb28WQkO7BWSUdAhWwZDX_znSQBc0gJ",
    network: Network.ARB_MAINNET,
    //network: Network.ETH_MAINNET,
};

export const faqList = [
    {
        question: "When will I recieve my filter?",
        answer: "Please allow for 3 business days, your filter will be emailed to you afterwards"
    },
    {
        question: "Does my filter expire?",
        answer: "We check ownership of the nft frequently so if you choose to sell your nft, your filter will be revoked"
    },
    {
        question: "Will more filters be accessible?",
        answer: "yes we are working with new projects to allow them to incorporate their nfts in our generator"
    },
]

export const blockchain = {
    receiverAddress: "0x5eA6A16D3B31885A0F75cb4E55bde5d4f1B50029",
    nftAbi: abi,
    price: "100000000"
}

export const whitelistedCollections = [
    "0x648cc6abcf7fe24afb76e6c15cdd9dfb41e9bcdb",
    //"0x2f73df859d0c5f42885ed4357fcb7ff38fbd1b4f",
]

export const gatingList = [
    "0x648cc6AbCf7fe24afb76E6c15CDd9DfB41e9BcdB"
]